<template>
    <!-- container -->
    <div class="container m-2">
        <!-- header -->
        <div class="header m-2">
            <p>header</p>
        </div>
        <!-- body -->
        <div class="body m-2 d-flex flex-row">
            <!-- left -->
            <div class="bg-info-light my-2 ml-2 mr-1" style="width: 50%">
                <draggable
                    :list="list1"
                    :group="{ name: 'my-group', pull:'clone', put: true}"
                    class="d-flex flex-row flex-wrap"
                    ghost-class="draggable-ghost"
                    @start="draggableStart"
                    @end="draggableEnd"
                >
                    <div v-for="data in list1" class="p-1">
                        <div class="bg-secondary d-flex justify-content-center align-items-center"
                             style="width: 150px; height: 80px">{{data.id}}: {{data.name}}
                        </div>
                    </div>
                </draggable>
            </div>
            <!-- right -->
            <div class="d-flex" style="width: 50%">
                <draggable
                    :list="list2"
                    group="my-group"
                    class="draggable-list p-2"
                    ghost-class="draggable-ghost"
                >
                    <div v-for="data in list2" class="d-inline-block">
                        <div class="bg-secondary d-flex justify-content-center align-items-center m-1 border"
                             style="width: 150px; height: 80px">{{data.id}}: {{data.name}}
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
        <!-- footer -->
        <div class="footer m-2">
            <p>footer</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Testing3",
    components: {},
    props: {},
    data() {
        return {
            draggableSelect: true,
            list1: [
                {id: 1, name: 'aaa'},
                {id: 2, name: 'bbb'},
                {id: 3, name: 'ccc'},
                {id: 4, name: 'ddd'},
                {id: 5, name: 'eee'},
                {id: 6, name: 'ananas'},
                {id: 7, name: 'hruška'},
                {id: 8, name: 'jabolka'},
                {id: 9, name: 'breskev'},
                {id: 10, name: 'sliva'},
            ],
            list2: [
                {id: 0, name: 'empty'},
            ],
        }
    },
    mounted() {
    },
    methods: {
        draggableStart: function (evt) {
            // console.log("start", evt);
            this.draggableSelect = true;
        },
        draggableEnd: function (evt) {
            // console.log("end", evt);
            this.draggableSelect = false;
        }
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

.draggable-list {
    background: whitesmoke;
    color: #fff;
    border: 1px solid;
    /*height: 50vh;*/
    flex: 1 0 auto;
    max-width: 100%;
}

.list-item {
    /*margin: 10px;*/
    /*padding: 40px;*/
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    background: #f44336;
    width: 150px;
    height: 100px;
    display: inline-block;
}

/*--  --*/
.container {
    background: silver;
    height: 700px;
    display: flex;
    flex-direction: column;
}

.header {
    background: lightblue;
}

.body {
    background: lightgreen;
    flex: 1 0 auto;
}

.draggable-container {
    background: #fd7e14;
    flex: 1 0 auto;
    max-width: 100%;
}

.draggable-container.draggable-select {
    background: #e83e8c;

}

.draggable-item {
    background: whitesmoke;
    width: 150px;
    height: 80px;
}

.draggable-ghost {
    /*border: gray 1px dashed;*/
    width: 155px;
    height: 80px;
    display: inline-block;
    opacity: 50%;
}

.footer {
    background: lightsalmon;
}
</style>
